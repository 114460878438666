<template>
  <thead>
    <tr>
      <th class="text-center">
        <a @click.prevent="lastWeek()" class="btn btn-sm" href="#">
          <i class="fas fa-angle-left"></i>
        </a>
      </th>
      <th colspan="5"></th>
      <th class="text-center">
        <a @click.prevent="nextWeek()" class="btn btn-sm" href="#">
          <i class="fas fa-angle-right"></i>
        </a>
      </th>
    </tr>
    <tr>
      <th v-for="(l,index) in weekDates" :key="index">{{ l.week_day }}</th>
    </tr>
    <tr>
      <th>Domingo</th>
      <th>Lunes</th>
      <th>Martes</th>
      <th>Miercoles</th>
      <th>Jueves</th>
      <th>Viernes</th>
      <th>Sabado</th>
    </tr>
  </thead>
</template>
<script>
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    ini_time: null,
    end_time: null,
    currentTime: null,
    weekDates: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  created() {
    this.currentTime = myMoment(myMoment().format("YYYYMMDD"), "YYYYMMDD");
    this.updateTimes(this.currentTime);
  },

  mounted() {
    //
  },

  methods: {
    updateTimes() {
      let ini_time = this.currentTime
        .clone()
        .add(-this.currentTime.day(), "days");
      let end_time = ini_time.clone().add(7, "days");
      this.ini_time = ini_time.format("YYYY-MM-DD");
      this.end_time = end_time.format("YYYY-MM-DD");
      this.weekDates = [];
      for (let index = 0; index < 7; index++) {
        this.weekDates.push({
          day: ini_time.day().toString(),
          week_day: ini_time.format("DD MMMM"),
          date: ini_time.format("YYYY-MM-DD")
        });
        ini_time.add(1, "days");
      }
    },
    nextWeek() {
      this.currentTime.add(7, "days");
      this.changesTime++;
      this.updateTimes();
      this.$emit('changeTime');
    },
    lastWeek() {
      this.currentTime.add(-7, "days");
      this.changesTime++;
      this.updateTimes();
      this.$emit('changeTime');
    }
  }
};
</script>

<style></style>
